import React from 'react';
import styled from 'styled-components';
import { MainTitle, XPBox } from '../Components';

const Bold = styled.span`
  font-weight: 600;
`;

const CV = () => {
  return (
    <>
      <section className='printable'>
        <title>Contact</title>
        <span>06 87 74 04 73</span>
        <span>come@pecorari.fr</span>
        <span>Aix-en-Provence</span>
        <MainTitle />
      </section>
      <h2>Expérience</h2>
      <XPBox
        xp={{
          company: 'Startup - Finance',
          date: 'Février 2023 - en cours',
          imgSrc:
            'https://img.freepik.com/vecteurs-libre/casier_53876-25496.jpg?size=626&ext=jpg&ga=GA1.1.1413502914.1696636800&semt=ais',
          imgAlt: 'lock',
          jobs: [
            {
              title: 'CTO associé',
              place: 'Remote Aix-en-Provence / Dubai',
              description: (
                <>
                  <p>Robot algorithmique de finance décentralisée sur Blockchain.</p>
                  <p>
                    Architecture du protocole et robot, définition des fonctionnalités et faisabilité des services.
                    Développement du robot, algoritmes et fonctionnalités avec 2 développeurs en{' '}
                    <Bold>NodeJS / TypeScript</Bold>.
                    <br />
                    Management de l'équipe technique, développement, mise en place des procédure de tests, de reviews,
                    et de déploiement CI/CD par github Actions, sur AWS avec Terraform.
                    <br />
                  </p>
                </>
              ),
            },
          ],
        }}
      />
      <br />
      <XPBox
        xp={{
          company: 'SmarDex - Real Estate Executive',
          date: 'Septembre 2022 - Janvier 2023',
          imgAlt: 'Smardex',
          imgSrc: './img/full-logo-smardex.png',
          jobs: [
            {
              title: 'Sénior Développeur Blockchain',
              place: 'Remote',
              description: (
                <>
                  <p>Protocole de finance décentralisée sur Blockchain.</p>
                  <a href='https://smardex.io' target='_blank' rel='noreferrer'>
                    SmarDex.io
                  </a>
                  <br />
                  <a href='https://github.com/SmarDex-Dev/smart-contracts' target='_blank' rel='noreferrer'>
                    GitHub contributor
                  </a>
                  <p>
                    Participation à l'architecture du protocole, définition des fonctionnalités et faisabilité des
                    services. Développement des smarts contracts (avec 1 développeur et CTO) en <Bold>solidity</Bold>.
                    <br />
                    Développement d'un subgraph (The-Graph) en WASM pour accéder aux datas historisées du protocole.
                    <br />
                    Gestion du développement, des tests, et de déploiement par <Bold>Hardhat</Bold>.
                    <br />
                    Gestion de version par <b>git</b>, branches, PR et reviews, conventional commit.
                    <br />
                    Participation et proposition sur la dApp en <Bold>React (TypeScript)</Bold>.
                    <br />
                  </p>
                </>
              ),
            },
          ],
        }}
      />
      <br />
      <XPBox
        xp={{
          company: 'Athena INS',
          date: 'Février 2022 - Septembre 2022',
          imgSrc:
            'https://media.licdn.com/dms/image/D4E0BAQEXW1XU1Vr5_Q/company-logo_200_200/0/1667597025899?e=2147483647&v=beta&t=2wg7c3g321xYffbQ8hMfcvFHZZuqWGodGHbGRMzQr4c',
          imgAlt: 'ATHENA',
          jobs: [
            {
              title: 'CTO - Architecte / Développeur React/Blockchain',
              place: 'Remote',
              description: (
                <>
                  <p>Startup d'assurance décentralisée sur Blockchain par la communauté pour la communauté.</p>
                  <p>
                    Architecture du protocole, définition des fonctionnalités et faisabilité des services. Développement
                    des smarts contracts (avec 1 développeur) en <Bold>solidity</Bold>.
                    <br />
                    Développement de front pour dApp en <Bold>React (TypeScript)</Bold>.
                    <br />
                    Gestion du développement, des tests, et de déploiement par <Bold>Hardhat</Bold>.
                    <br />
                    Gestion de version par <b>git</b>, branches, rebase, conventional commit.
                    <br />
                    Mise en place de bonnes pratiques, de tests unitaires (<b>Chai/Mocha</b>).
                  </p>
                </>
              ),
            },
          ],
        }}
      />
      <br />
      <XPBox
        xp={{
          company: 'Startup Confidentielle',
          date: 'Janvier 2021 - Février 2022',
          imgSrc:
            'https://img.freepik.com/vecteurs-libre/casier_53876-25496.jpg?size=626&ext=jpg&ga=GA1.1.1413502914.1696636800&semt=ais',
          imgAlt: 'lock',
          jobs: [
            {
              title: 'CTO associé - Développeur TS / Node / Blockchain',
              place: 'Ivry-sur-Seine',
              description: (
                <>
                  <p>
                    Startup développant un robot d'arbitrage (trading) sur les marchés décentralisés de cryptomonnaies
                    (DeFi)
                  </p>
                  <p>
                    Développement du robot (avec 2 développeurs salariés) en
                    <b>TypeScript</b>, <b>Web3js</b> et <b>Ethersjs</b> pour l'algorithme de détection et calcul des
                    opportunités d'arbitrage.
                    <br />
                    Optimisation des temps de calcul sur des workers pour faire du multi-thread JS (monothreadé par
                    défaut).
                    <br />
                    Développement de smart contract modulaire (<Bold>Solidity</Bold>) pour ajouter des marchés
                    décentralisés (DEX) progressivement.
                    <br />
                    Interfaçage par Flashbots (MEV relay) pour lancer les transactions privées sans passer par la
                    mempool Ethereum et éviter le frontrunning.
                    <br />
                    Développement de l'interface et de l'API de pilotage et de monitoring du robot (dApp).
                    <br />
                    Développement front en <Bold>React</Bold> et backend en <Bold>Node</Bold> avec <Bold>MongoDB</Bold>{' '}
                    puis <Bold>Redis</Bold> pour des questions de performances.
                    <br />
                    Optimisations MongoDB avec index, mongoosePaginate et Aggregates.
                    <br />
                    Gestion de version par <b>git</b>, branches, rebase, conventional commit.
                    <br />
                    Mise en place de bonnes pratiques, de tests unitaires (<b>Jest</b>).
                    <br />
                    Mise en place et gestion de l'infrastructure Docker, nodes Ethereum et Binance Geth et OpenEthereum.
                  </p>
                </>
              ),
            },
          ],
        }}
      />
      <br />
      <XPBox
        xp={{
          company: 'MARKUS',
          imgSrc: 'https://www.agence-markus.com/images/logo-markus.png',
          imgAlt: 'markus',
          date: 'Décembre 2020 - Janvier 2021',
          jobs: [
            {
              title: 'Développeur React & Node PWA',
              place: 'Télétravail',
              description: (
                <>
                  <p>
                    Markus développe une application web <Bold>PWA</Bold> pour un promoteur immobilier, pour le
                    processus de vente d'appartements sur plan.
                  </p>
                  <p>
                    Architecture from scratch de l'application sur base de maquette d'un UX/UI designer.
                    <br />
                    Développement de l'application en <Bold>React</Bold> et participation au développement du backend en{' '}
                    <Bold>Node</Bold>. Développement d'une base de composants réutilisables pour l'application.
                    <br />
                    Animations des changements de pages avec look & feel d'application native.
                  </p>
                </>
              ),
            },
          ],
        }}
      />
      <br />
      <XPBox
        xp={{
          company: 'COPRISTO',
          date: 'Septembre 2019 - Mai 2024',
          imgSrc: '/img/logo_copristo.png',
          imgAlt: 'COP',
          jobs: [
            {
              title: 'CxO',
              place: 'Boulogne-Billancourt',
              description: (
                <>
                  <p>
                    COPRISTO est une application web et mobile pour gérer sa copropriété, et sauvegarder tout ce qu'il
                    s'y passe sans risque de perte lors du départ d'un gestionnaire, d'un copropriétaire qui avait tout
                    en mémoire...
                  </p>
                  <p>
                    Développement avec des syndics et des copropriétés pour leur faire gagner du temps au quotidien
                    Développement de l'application web en <Bold>MERN (MongoDB, Express, React, Node.JS)</Bold>.
                    Développement des applications mobiles iOS et Android en <Bold>React Native</Bold>.
                  </p>
                  <p>
                    Principalement développé en TDD, les tests unitaires sont importants (Jest), tout comme les tests
                    e2e (End-to-end) avec Cypress.io{' '}
                  </p>
                  <p>
                    Essayez gratuitement sur{' '}
                    <a href='https://app.copristo.com' target='_blank' rel='noreferrer'>
                      la web app
                    </a>{' '}
                    ou apprenez-en plus sur{' '}
                    <a href='https://www.copristo.com' target='_blank' rel='noreferrer'>
                      le site de présentation.
                    </a>
                  </p>
                </>
              ),
            },
          ],
        }}
      />
      <br />
      <XPBox
        xp={{
          jobs: [
            {
              title: 'Directeur Technique',
              description: (
                <p>
                  Responsable des Appels d'Offres
                  <br />
                  Développements au forfait <Bold>(C++, Qt, Linux, C embarqué... )</Bold>
                  <br />
                  Gestion des activités internes (projets, formations et outils - Slack, GSuite, Matériel...)
                </p>
              ),
              date: '3ans (2016 - 2019)',
            },
            {
              title: "Business Manager & Directeur d'Agence",
              description: (
                <p>
                  Ingénieur d'affaire puis Responsable d'Agence et enfin Directeur d'Agence, avec 2 ingénieurs
                  d'Affaires et 70 consultants pour des clients Automobile, TV Numérique, Défense.
                  <br />
                  Croissance de chiffre d'Affaire de 300K€ à 3M€
                </p>
              ),
              date: '4ans (2015 - 2019)',
            },
          ],
          date: '4 ans (2015 - 2019)',
          company: 'Altim France',
          imgSrc: 'https://pbs.twimg.com/profile_images/1172490389951012864/MUVThefF_400x400.jpg',
          imgAlt: 'ALTIM',
        }}
      />
      <br />
      <XPBox
        xp={{
          company: 'Alten / PSA Peugeot Citroën',
          imgSrc: './img/PSA-logo.png',
          imgAlt: 'PSA',
          date: '5 ans (2010 - 2015)',
          jobs: [
            {
              title: 'Team Lead',
              date: '2013 - 2015',
              place: 'Région Parisienne',
              description: (
                <p>
                  Management de 10 ingénieurs et développeurs pour des projets infodivertissement automobile dans
                  l'innovation. Gestion des développements logiciels et plateformes Linux en Agile/SCRUM pour améliorer
                  :
                  <br /> - Qualité de livraison
                  <br /> - Planning de livraison
                  <br /> - Organisation de l'équipe
                  <br /> - Esprit d'éqauipe
                  <br /> - Visibilité sur les futurs développements
                </p>
              ),
            },
            {
              title: 'Développeur',
              date: '2010 - 2015',
              description: (
                <p>
                  Consultant Alten en mission chez PSA, pour le développement d'une plateforme de prototypage et
                  simulation dans l'infodivertissement automobile :
                  <br /> - Développement d'applications en <Bold>JS/C++/Qt/QML</Bold> selon spécifications
                  <br /> - Echanges et améliorations selon ergonomes et UI Designers
                  <br /> - Configuration et compilation de plateforme Linux
                  <br />
                  - Pilotage d'un fournisseur pour les priorités de développements de fonctionnalités et drivers Linux
                  pour la plateforme selon spécifications GENIVI
                  <br /> - Pilotage du développement hardware
                  <br /> - Participation à la définition d'une chaîne outillée permettant d'accélérer la délivrabilité
                  de spécifications au fournisseur de la version de production pour les véhicules de série
                </p>
              ),
            },
          ],
        }}
      />
      <br />
      <br />
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <img
          src='https://static.vecteezy.com/ti/vecteur-libre/p3/11405698-creation-de-logo-de-formation-en-genie-industriel-modele-de-logo-d-engrenage-etudiant-vectoriel.jpg'
          alt='formationImg'
          style={{ width: 100 }}
        />
        <h2>Formations</h2>
      </div>
      <XPBox
        xp={{
          jobs: [
            {
              description: (
                <p>
                  La Capsule Academy - Coding Bootcamp · (2019 - 2019)
                  <br />
                  ESTACA - Ecole Supérieure des Techniques Aéronautiques et de Construction Automobile Systèmes
                  embarqués · (2004 - 2010)
                  <br />
                  Orsys : BSP UBoot et Linux embarqué, Linux Development · (2012)
                </p>
              ),
            },
          ],
        }}
      />
    </>
  );
};

export { CV };
